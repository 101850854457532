<template>
  <div class="results2" id="hmmm"> 

    <div v-if="(!filteredQuestions || filteredQuestions.length == 0)" style="display:flex;flex-direction:column;justify-content: center;align-items: center;gap: 32px;height: 100%; padding-top:80px;">
      <div style="font-size:20px;color:#a2a2a2;">There are no questions in this segment</div>
      <img src="../assets/no_questions.svg" style="width:128px;height:128px;" />
    </div>
    
    <div v-for="question in filteredQuestions" :key="question.id" :questionId="question.id" class="question" :class="[question.type, {ranking: question.ranking}]" :id="'imgexport'+question.id">      
      <div class="title" @click="toggle">
        {{stripHtml(question.Qdashboard ? question.Qdashboard : question.Q)}}
        <div class="totalcount"><span :class="{zero: answerCount(question) == 0}">{{answerCount(question)}}</span> Answers</div>
      </div>          
      <div class="answers">        
        
        <table v-if="question.ranking">
          <tr>
            <th></th>
            <th style="text-align:center; width:10%;">Score</th>
            <th>Distribution by ranking</th>
            <!-- <th class="palette">
              Lowest
              <span v-for="index in 6" :key="index" :style="{backgroundColor: getRankColor(5-(index-1), 5)}"></span>
              Highest
            </th> -->
          </tr>
          <tr v-for="rankedOption in resRank(question)" :key="rankedOption.option.id" class="option" :class="{hasImage: rankedOption.option.image}">            
            <td class="label">
              <div>
                <img v-if="rankedOption.option.image" :src="contentRoot + rankedOption.option.image" width="100" />
                <span>{{stripHtml(rankedOption.option.textDashboard ? rankedOption.option.textDashboard : rankedOption.option.text)}}</span>
              </div>
            </td>
            <td class="score">              
              {{rankedOption.score}}              
            </td>
            <td class="chart">
              <div class="bar">
                <div v-for="(val, index) in rankedOption.distribution" :key="index" class="progress distribution" v-bind:style="{ flexGrow: val, backgroundColor: getRankColor(index, rankedOption.distribution.length-1, val), opacity: val > 0 ? 1 : 0.3 }">
                  <div class="value">{{val > 0 ? `${val}%` : ''}}</div>
                </div>
              </div>              
            </td>
          </tr>
          <tr>
            <td colspan="3" class="palette">
              Highest
              <span v-for="index in question.options.length" :key="index" :style="{backgroundColor: getRankColor(index-1, question.options.length-1)}"></span>
              Lowest
            </td>
          </tr>
        </table>

        <table v-else-if="question.type == 'likert'">
          <tr>
            <th style="text-align: left; width:30%;">Statements</th>
            <th style="text-align:center; width:10%;">Avg score</th>
            <th style="text-align:center; width:10%;">Std dev.</th>            
            <th></th>
          </tr>
          <tr v-for="option in question.options" :key="option.id" class="option">
            <td class="label" style="width:40%;">              
              <div>                
                <span>{{stripHtml(option.textDashboard ? option.textDashboard : option.text)}}</span>
              </div>
            </td>
            <td style="text-align: center;">
              {{resAverage(question.id, option.id)}}              
            </td>
            <td style="text-align: center;">
              {{resStandardDeviation(question.id, option.id)}}    
            </td>
            <td class="chart">
              <div>
                <div v-for="(res, idx) in resLikert(question.id, option.id)" :key="option.id + idx" v-bind:style="{ backgroundColor: getLikertColor(idx,4), opacity: res.count == 0 ? 0.3 : 1, color: res.count == 0 ? '#fff' : '#000', flexGrow: res.percentage, flexBasis: '30px' }">
                  {{res.count > 0 ? `${(res.percentage*100).toFixed(1)}%` : ''}}
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="4" class="palette">     
              <template v-for="(desc,index) in question.descriptors">        
                <span  :key="index" :style="{backgroundColor: getLikertColor(index, question.descriptors.length-1)}"></span>{{desc}}           
              </template>
            </td>
          </tr>
        </table>
        
        <table v-else-if="question.type == 'rating' && !question.options.length /*text rating*/">
          <tr>
            <th style="text-align:left;">Avg score</th>
            <th style="text-align:center; width:10%;">Rating</th>
            <th></th>
          </tr>
          <tr v-for="(option,idx) in [5,4,3,2,1].map(x => ({id: x, text: `${(x).toString()} star${x > 1 ? 's' : ''}`}))" :key="option.id" class="option">
            <td v-if="idx == 0" rowspan="5" class="summary" style="width:50%; border-bottom:0px;vertical-align:top; padding-top:0;">
              <div style="font-size:40px;">{{resAverage(question.id)}}</div>
              <div class="stars">
                <div :style="`clip-path: inset(0% ${Math.round(100 - resAverage(question.id)/5*100)}% 0% 0%);`"></div>
              </div>
              <div style="color:#777; margin-top:40px;">Std deviation</div>
              <div>{{resStandardDeviation(question.id)}}</div>
            </td>
            <td style="text-align: center;">
              {{stripHtml(option.text)}}
            </td>
            <td class="chart">
              <div class="bar">
                <div class="progress" v-bind:style="{ width: resPercentage(question.id, option.id)+'%' }"></div>
                <div class="value">{{res(question.id, option.id).length}}</div>
              </div>                
            </td>
          </tr>
        </table>

        <table v-else-if="question.type == 'rating' && question.options.length /* image rating */">
          <tr>
            <th style="width:40%;"></th>
            <th style="text-align:center; width:10%">Avg score</th>
            <th style="text-align:center; width:10%;">Std dev</th>
            <th style="text-align:left;">Rating</th>
          </tr>
          <!-- <tr>
            <th></th>
            <th></th>
            <th></th>
            <th style="text-align:left;">
              <div style="display: grid; gap: 2px; grid-template-columns: 1fr 1fr 1fr 1fr 1fr;">
                <div v-for="x in [1,2,3,4,5]" :key="x">
                  <img v-for="y in x" :key="x+y" src="../assets/yellowstar.svg" style="width:12px" />
                </div>
              </div>
            </th>
          </tr> -->
          <tr v-for="(option,idx) in question.options" :key="option.id" class="option" :class="{hasImage: option.image, noborder: idx == question.options.length-1}">
            <td class="label" style="width:40%;">
              <div>
                <img v-if="option.image" :src="contentRoot + option.image" width="100" />
                <span>{{stripHtml(option.textDashboard ? option.textDashboard : option.text)}}</span>
              </div>
            </td>
            <td style="text-align: center;">
              {{resAverage(question.id, option.id)}}
            </td>
            <td style="text-align: center;">
              {{resStandardDeviation(question.id, option.id)}}
            </td>
            <td class="chart" style="position:relative;">
              <div v-if="idx == 0" style="display: grid; gap: 2px; grid-template-columns: 1fr 1fr 1fr 1fr 1fr; position:absolute; background-color:transparent; top:-7px; width:100%;">
                <div v-for="x in [1,2,3,4,5]" :key="x" style="background-color:transparent; text-align:left;">
                  <img v-for="y in x" :key="x+y" src="../assets/yellowstar.svg" style="width:12px" />
                </div>
              </div>
              <div>
                <div v-for="(res, idx) in resLikert(question.id, option.id, true)" :key="option.id + idx" v-bind:style="{ backgroundColor: res.color, color: res.count == 0 ? '#fff' : '#000' }">
                  {{res.count}}
                </div>
              </div>                
            </td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td class="palette" style="padding:0;">
              Lowest 
              <span v-for="index in 5" :key="index" :style="{backgroundColor: getRatingColor(index-1, 4)}"></span>
              Highest
            </td>
          </tr>
        </table>

        <!-- type 1 -->
        <template v-else-if="question.type == 'interview' && !question.followupQuestions && !question.chooseQuestions && question.interviewees && question.interviewees.length > 1">
          <div class="buttons" v-if="question.tags && question.tags.length > 0">
            <div :class="{selected: question.mode == 'byIv'}" @click="toggleInterviewMode(question,'byIv')">By interviewee</div>
            <div :class="{selected: question.mode == 'byTag'}" @click="toggleInterviewMode(question,'byTag')">By tag</div>
            <!-- {{question.mode}} -->
          </div>
          <table v-show="question.mode == 'byIv'"> 
            <tr>
              <th colspan="5" style="text-align:left; padding: 10px 0;">Selection per interviewee</th>
            </tr>                      
            <tr>
              <th></th>
              <th>Rank</th>
              <th style="text-align:left;">Interviewee</th>
              <th>Avg. depth</th>
              <th style="text-align:left;">Number of times chosen by learners</th>
            </tr>
            <template v-for="(interviewee, ividx) in resInterview(question)">
              <tr :key="'iv_'+interviewee.id" class="option hasImage" :class="{collapsed: !(question.interviewees.find(i => i.id == interviewee.id).expanded), last: ividx == question.interviewees.length-1}"
                  @click="toggleInterviewee(question, interviewee)">
                <td class="toggle"></td>
                <td style="text-align:center; padding-right:8px;" class="rank">{{ividx+1}}</td>
                <td class="label">              
                  <div>
                    <img v-if="interviewee.icon" :src="contentRoot + interviewee.icon" width="48" height="48" />
                    <span>{{stripHtml(interviewee.text)}}</span>
                  </div>
                </td>
                <td style="text-align: center;">
                  {{interviewee.averageDepth}}
                </td>
                <td class="chart">
                  <div class="bar">
                    <div class="progress" v-bind:style="{ width: interviewee.percentage*100+'%' }"></div>
                    <div class="value">{{interviewee.count}}</div>
                  </div>
                  <div class="funnel">
                    <div v-for="(q, qidx) in resFunnel(interviewee.questions)" :key="'ivf_'+interviewee.id+_q.optionId+qidx" 
                      class="col"
                      v-bind:style="{ 
                        backgroundColor: getInterviewColor(qidx, interviewee.questions.length-1, q.count), 
                        color: (q.count == 0 ? 'white' : 'black'),
                        clipPath: q.clipPath
                      }">                      
                    </div>
                    <div class="values">
                      <div v-for=" (q, qidx) in interviewee.questions" :key="'ivf_'+interviewee.id+_q.optionId+qidx">
                        {{q.count}}
                      </div>
                    </div>
                  </div>
                </td>
              </tr>   
              <tr v-for="(q, qidx) in interviewee.questions" :key="`ivq_${interviewee.id}${q.optionId}`" class="ivq" :class="{collapsed: !(question.interviewees.find(i => i.id == interviewee.id).expanded), last: qidx == interviewee.questions.length-1}">
                <td></td>
                <td style="text-align:center; padding-right:8px; color: #ccc;"></td>
                <td colspan="2">
                  <div class="left">
                    <span class="dot" :style="{backgroundColor: getInterviewColor(qidx, interviewee.questions.length-1, q.count), color: (q.count == 0 ? 'white' : 'black')}">{{q.count}}</span>
                    <div>
                      {{stripHtml(q.title ? q.title : q.question)}}
                      <span class="tag" v-if="q.tag" :style="{backgroundColor: q.color.bg, borderColor: q.color.border, color: q.color.font}">{{q.tag}}</span>
                    </div>
                  </div>
                </td>
                <td v-if="qidx == 0" :rowspan="interviewee.questions.length" style="text-align:center;">
                  <canvas class="tagchart" :id="`tagchart_${interviewee.id}`" :questionId="question.id"></canvas>
                </td>
              </tr>     
            </template>            
          </table>
          <table v-show="question.mode == 'byTag'">   
            <tr>
              <th></th>
              <th style="text-align:left;">Number of questions</th>
            </tr>
            <template v-for="tag in resInterviewByTag(question)">
              <tr :key="'tag'+tag.name" class="option collapsed">
                <td style="width:50%;"><span class="tag" :style="{backgroundColor: tag.color.bg, borderColor: tag.color.border, color: tag.color.font}">{{tag.name}}</span></td>
                <td class="chart">
                  <div class="bar">
                    <div class="progress" v-bind:style="{ width: tag.percentage*100+'%' }"></div>
                    <div class="value">{{tag.count}} <span style="opacity:0.5;"> / {{(tag.percentage*100).toFixed()+'%'}}</span></div>
                  </div>
                </td>
              </tr>    
            </template>  
          </table>
        </template>

        <!-- type 2 -->
        <template v-else-if="question.type == 'interview' && !question.followupQuestions && question.chooseQuestions && question.interviewees && question.interviewees.length > 1">
          <div class="buttons" v-if="question.tags && question.tags.length > 0">
            <div :class="{selected: question.mode == 'byIv'}" @click="toggleInterviewMode(question,'byIv')">By interviewee</div>
            <div :class="{selected: question.mode == 'byTag'}" @click="toggleInterviewMode(question,'byTag')">By tag</div> 
            <div :class="{selected: question.mode == 'top5_desc'}" @click="toggleInterviewMode(question,'top5_desc')">Top 5 questions</div>            
            <div :class="{selected: question.mode == 'top5_asc'}" @click="toggleInterviewMode(question,'top5_asc')">Bottom 5 questions</div>            
          </div>
          <table class="type2" v-show="question.mode == 'byIv'"> 
            <tr>
              <th colspan="4" style="text-align:left; padding: 10px 0;">Selection per interviewee</th>
            </tr>                       
            <tr>
              <th></th>
              <th style="text-align:left;">Interviewee</th>
              <th>Total</th>
              <th style="text-align:left;">Questions chosen</th>
            </tr>
            <template v-for="(interviewee, ividx) in resInterview(question)">
              <tr :key="interviewee.id+'iv'" class="option hasImage" :class="{collapsed: !question.interviewees.find(i => i.id == interviewee.id).expanded, last: ividx == question.interviewees.length-1}"
                  @click="toggleInterviewee(question, interviewee)">
                <td class="toggle"></td>
                
                <td class="label">              
                  <div>
                    <img v-if="interviewee.icon" :src="contentRoot + interviewee.icon" width="48" height="48" />
                    <span>{{stripHtml(interviewee.text)}}</span>
                  </div>
                </td>
                <td style="text-align: center;">
                  {{interviewee.questionCount}}
                </td>
                <td class="chart">
                  <div class="distribution">
                    <div v-for="q in interviewee.questions" :key="`${interviewee.id}${q.optionId}qd`" v-bind:style="{ backgroundColor: getRatingColor(q.colorRank, interviewee.colorRankBase, q.count), color: q.count == 0 ? '#fff' : '#000' }">
                      {{q.count}}
                    </div>
                  </div>
                </td>
              </tr>   
              <tr v-for="(q, qidx) in interviewee.questions" :key="`${interviewee.id}${q.optionId}q`" class="ivq" :class="{collapsed: !question.interviewees.find(i => i.id == interviewee.id).expanded, last: qidx == interviewee.questions.length-1}">                
                <td></td>
                <td colspan="2">       
                  <div class="left">         
                    <span class="dot" :style="{backgroundColor: getRatingColor(q.colorRank, interviewee.colorRankBase, q.count), color: q.count == 0 ? '#fff' : '#000'}">{{q.count}}</span>
                    <div>
                      {{stripHtml(q.title ? q.title : q.question)}}                                           
                      <span class="tag" v-if="q.tag" :style="{backgroundColor: q.color.bg, borderColor: q.color.border, color: q.color.font}">{{q.tag}}</span>
                    </div>
                  </div>
                </td>
                <td v-if="qidx == 0" :rowspan="interviewee.questions.length" style="text-align:center;">
                  <canvas class="tagchart" :id="`tagchart_${interviewee.id}`" :questionId="question.id"></canvas>
                </td>
              </tr>     
            </template>   
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td class="palette">
                Lowest 
                <span v-for="index in 8" :key="index" :style="{backgroundColor: getRatingColor(index-1, 7)}"></span>
                Highest
              </td>
            </tr>       
          </table>
          <table v-show="question.mode == 'byTag'">   
            <tr>
              <th></th>
              <th style="text-align:left;">Number of questions</th>
            </tr>
            <template v-for="tag in resInterviewByTag(question)">
              <tr :key="'tag'+tag.name" class="option collapsed">
                <td style="width:50%;"><span class="tag" :style="{backgroundColor: tag.color.bg, borderColor: tag.color.border, color: tag.color.font}">{{tag.name}}</span></td>
                <td class="chart">
                  <div class="bar">
                    <div class="progress" v-bind:style="{ width: tag.percentage*100+'%' }"></div>
                    <div class="value">{{tag.count}}  <span style="opacity:0.5;"> / {{(tag.percentage*100).toFixed()+'%'}}</span></div>
                  </div>
                </td>
              </tr>    
            </template>  
          </table>
          <table class="type2" v-show="question.mode.startsWith('top5')">
            <tr>
              <th colspan="4" style="text-align:left; padding: 10px 0;">{{question.mode.split('_')[1] == 'asc' ? 'Least' : 'Most'}} asked questions</th>
            </tr> 
            <tr>
              <th>Rank</th>
              <th style="text-align:left;">Question</th>
              <th>Total</th>
              <th style="text-align:left;">Questions - overall frequency</th>
            </tr>
            <tr v-for="i in resInterviewTop5(question, question.mode.split('_')[1])" :key="'t5' + i.interviewee.id + i.question.optionId" class="option hasImage">
              <td>{{i.rank}}</td>
              <td class="label">              
                <div>
                  <img v-if="i.interviewee.icon" :src="contentRoot + i.interviewee.icon" width="48" height="48" />
                  <span style="font-weight:normal;">{{stripHtml(i.question.title)}}
                    <span class="tag" v-if="i.question.tag" :style="{backgroundColor: i.question.color.bg, borderColor: i.question.color.border, color: i.question.color.font}">{{i.question.tag}}</span>
                  </span>                   
                </div>
              </td>                
              <td style="text-align:center;">{{i.question.count}}</td>
              <td class="chart">
                <div class="bar showWhenExpanded">
                  <div class="progress" v-bind:style="{ width: i.percentage*100+'%' }"></div>
                  <div class="value">{{(i.percentage*100).toFixed()+'%'}}</div>
                </div>
              </td>
            </tr>
          </table>
        </template>

        <!-- type 3 -->
        <template v-else-if="question.type == 'interview' && question.followupQuestions">
          <div class="buttons" v-if="question.tags && question.tags.length > 0">
            <div :class="{selected: question.mode == 'byIv'}" @click="toggleInterviewMode(question,'byIv')">By interviewee</div>
            <div :class="{selected: question.mode == 'top5_desc'}" @click="toggleInterviewMode(question,'top5_desc')">Top 5 questions</div>            
            <div :class="{selected: question.mode == 'top5_asc'}" @click="toggleInterviewMode(question,'top5_asc')">Bottom 5 questions</div>            
          </div>
          <table class="type3" v-show="question.mode == 'byIv'">  
            <tr>
              <th colspan="4" style="text-align:left; padding: 10px 0;">Selection per interviewee</th>
            </tr>                    
            <tr>
              <th></th>
              <th style="text-align:left;">Interviewee</th>
              <th>Total</th>
              <th style="text-align:left;">Overall per interviewee</th>
            </tr>
            <template v-for="(interviewee, ividx) in resInterview(question)">
              <tr :key="interviewee.id+'iv'" class="option hasImage" :class="{collapsed: !question.interviewees.find(i => i.id == interviewee.id).expanded, last: ividx == question.interviewees.length-1}"
                  @click="toggleInterviewee(question, interviewee)">
                <td class="toggle"></td>
                
                <td class="label">              
                  <div>
                    <img v-if="interviewee.icon" :src="contentRoot + interviewee.icon" width="48" height="48" />
                    <span>{{stripHtml(interviewee.text)}}</span>
                  </div>
                </td>
                <td style="text-align: center;">
                  {{interviewee.questionCount}}
                </td>
                <td class="chart">
                  <div class="bar showWhenExpanded">
                    <div class="progress" v-bind:style="{ width: interviewee.percentage*100+'%' }"></div>
                    <div class="value">{{(interviewee.percentage*100).toFixed()+'%'}}</div>
                  </div>
                </td>
              </tr>   
              <tr :key="`${interviewee.id}fu`" class="ivq last" :class="{collapsed: !question.interviewees.find(i => i.id == interviewee.id).expanded}">   
                <td colspan="4">
                  <div class="followup">

                    <div class="q">
                      <div v-for="q in interviewee.questions/*.filter(x => x.selected || !interviewee.questions.find(y => y.selected))*/" :key="`${interviewee.id}fu${q.optionId}`"
                        v-show="q.selected || !(interviewee.questions.find(y => y.selected))"
                      >
                        <div class="count" :class="{zero: q.count == 0}">{{q.count}}</div>
                        <div class="fuQ" :class="{zero: q.count == 0}" @click="toggleFuQ(q, interviewee)">
                          <div class="lineclamp">
                            <div class="tagfloater"><span class="tag" v-if="q.tag" :style="{backgroundColor: q.color.bg, borderColor: q.color.border, color: q.color.font}">{{q.tag}}</span></div>
                            {{stripHtml(q.title ? q.title : q.question)}}
                          </div>
                        </div>
                        <div class="arrow" :class="{right: q.selected}"></div>
                      </div>
                    </div>

                    <!-- <div class="arrows" style="flex: 0 0 58px;">arrows</div> -->

                    <div class="q" v-if="interviewee.questions.find(x => x.selected)">
                      <div v-for="q in interviewee.questions.find(x => x.selected).followupQuestions" :key="`${interviewee.id}fu${q.optionId}`"
                        v-show="q.selected || !(interviewee.questions.find(y => y.selected).followupQuestions.find(x => x.selected))"
                      >
                        <div class="arrow left" :class="{selected: q.selected}"></div>
                        <div class="count" :class="{zero: q.count == 0}">{{q.count}}</div>
                        <div class="fuQ" :class="{zero: q.count == 0}" @click="toggleFuQ(q, interviewee)">
                          <span class="tag" v-if="q.tag" :style="{backgroundColor: q.color.bg, borderColor: q.color.border, color: q.color.font}">{{q.tag}}</span>
                          {{stripHtml(q.title ? q.title : q.question)}}
                        </div>
                        <div class="arrow" :class="{right: q.selected}" v-if="interviewee.questions.find(x => x.selected) && interviewee.questions.find(x => x.selected).followupQuestions && interviewee.questions.find(x => x.selected).followupQuestions.find(x => x.selected) && interviewee.questions.find(x => x.selected).followupQuestions.find(x => x.selected).followupQuestions"></div>
                      </div>
                    </div>

                    <!-- <div style="flex: 0 0 58px;">arrows</div> -->

                    <div class="q" v-if="interviewee.questions.find(x => x.selected) && interviewee.questions.find(x => x.selected).followupQuestions && interviewee.questions.find(x => x.selected).followupQuestions.find(x => x.selected)">
                      <div v-for="q in interviewee.questions.find(x => x.selected).followupQuestions.find(x => x.selected).followupQuestions" :key="`${interviewee.id}fu${q.optionId}`">
                        <div class="arrow left" :class="{selected: q.selected}"></div>
                        <div class="count" :class="{zero: q.count == 0}">{{q.count}}</div>
                        <div class="fuQ" :class="{zero: q.count == 0}">
                          <div class="tagfloater"><span class="tag" v-if="q.tag" :style="{backgroundColor: q.color.bg, borderColor: q.color.border, color: q.color.font}">{{q.tag}}</span></div>
                          {{stripHtml(q.title ? q.title : q.question)}}
                        </div>
                      </div>
                    </div>
                  </div>
                </td>             
                <!-- <td></td>
                <td colspan="3">
                  <span class="dot" :style="{backgroundColor: getRatingColor(interviewee.questions.length-q.rank, interviewee.questions.length, q.count)}">{{q.count}}</span>
                  {{stripHtml(q.title ? q.title : q.question)}} 
                  <span class="tag" v-if="q.tag" :style="{backgroundColor: getTagColor(question.tags, q.tag)}">{{q.tag}}</span>
                </td> -->
              </tr>     
            </template>            
          </table>     
          <table class="type3" v-show="question.mode.startsWith('top5')">
            <tr>
              <th colspan="4" style="text-align:left; padding: 10px 0;">{{question.mode.split('_')[1] == 'asc' ? 'Least' : 'Most'}} asked questions</th>
            </tr>
            <tr>
              <th>Rank</th>
              <th style="text-align:left;">Question</th>
              <th>Total</th>
              <th style="text-align:left;">Questions - overall frequency</th>
            </tr>
            <tr v-for="i in resInterviewTop5(question, question.mode.split('_')[1])" :key="'t5' + i.interviewee.id + i.question.optionId" class="option hasImage">
              <td>{{i.rank}}</td>
              <td class="label">              
                <div>
                  <img v-if="i.interviewee.icon" :src="contentRoot + i.interviewee.icon" width="48" height="48" />
                  <span style="font-weight:normal;">{{stripHtml(i.question.title)}}
                    <span class="tag" v-if="i.question.tag" :style="{backgroundColor: i.question.color.bg, borderColor: i.question.color.border, color: i.question.color.font}">{{i.question.tag}}</span>
                  </span>                   
                </div>
              </td>                
              <td style="text-align:center;">{{i.question.count}}</td>
              <td class="chart">
                <div class="bar showWhenExpanded">
                  <div class="progress" v-bind:style="{ width: i.percentage*100+'%' }"></div>
                  <div class="value">{{(i.percentage*100).toFixed()+'%'}}</div>
                </div>
              </td>
            </tr>
          </table>
        </template>

        <table v-else>
          <tr>
            <th></th>
            <th style="text-align:center; width:10%;">Answers</th>
            <th>              
              <span class="correctcount" v-if="showDetails && (question.Answer || (question.Answers && question.Answers.length))">{{resCorrect(question)}} users got all answers correct</span>
            </th>
          </tr>
          <tr v-for="option in question.options" :key="option.id" class="option" :class="{hasImage: option.image}">
            <td class="label" :class="{correct: showDetails && (question.Answer == option.id || (question.Answers && question.Answers.includes(option.id)))}">              
              <div>
                <img v-if="option.image" :src="contentRoot + option.image" width="48" height="48" />
                <span>{{stripHtml(option.textDashboard ? option.textDashboard : option.text)}}</span>
              </div>
            </td>
            <td style="text-align: center;">
              {{res(question.id, option.id).length}}
            </td>
            <td class="chart">
              <div class="bar" style="display:block;">
                <div class="progress" v-bind:style="{ width: resPercentage(question.id, option.id)+'%' }"></div>
                <div class="value">{{resPercentage(question.id, option.id)}}%</div>
              </div>
              <div v-if="showDetails && question.showValueAverage" class="average">
                {{question.valueAverageLabel ? question.valueAverageLabel : 'data average' }}: <span>{{resAverage(question.id, option.id)}}</span>
              </div>
              <div v-if="showDetails && question.showValueSum" class="sum">
                {{question.valueSumLabel ? question.valueSumLabel : 'data sum' }}: <span>{{resSum(question.id, option.id)}}</span>
              </div>
            </td>
          </tr>
        </table>

      </div>
    </div>    

    <Snackbar ref="snackbar" /> 

  </div>
</template>



<script>

//import groupBy from 'lodash/groupBy';
//import toArray from 'lodash/toArray';
//import chain from 'lodash/chain';
//import xor from 'lodash/xor';
import _ from 'lodash';
import { saveAs } from 'file-saver'
import chroma from 'chroma-js'
//import html2canvas from 'html2canvas'
import * as htmlToImage from 'html-to-image'
import Snackbar from '@/components/Snackbar.vue'
import Chart from 'chart.js/auto'
import ChartDataLabels from 'chartjs-plugin-datalabels'

export default {
  name: 'Results2',
  components: {
    Snackbar
  },
  props: {
    conditions: Array,
    questions: Array,
    results: Array,
    contentRoot: String,
    segmentId: Number    
  },
  data: function() {
    return {
      showDetails: true,      
      tempVueBugHack: 'whatever'
    };
  },
  computed: {
    filteredQuestions(){
      //console.log('filteredQuestions computed')
      if(!this.tempVueBugHack)
        return []      
      return this.questions?.filter(q => q.segmentId == this.segmentId)      
    }    
  },  
  methods: {
    async exportcanvas(){
      // html2canvas(document.querySelector("#wtfimg"), 
      //   {
      //     useCORS: true,
      //     //allowTaint: true,
      //   }
      // ).then(canvas => {
      //     document.body.appendChild(canvas)
      // })

      // htmlToImage.toCanvas(document.querySelector("#hmmm"), {backgroundColor: "white", pixelRatio: 1})
      // .then(function (canvas) {
      //   document.body.appendChild(canvas)
      // })
      let item
      await htmlToImage.toBlob(document.getElementById('hmmm'), {cacheBust: true, backgroundColor: "white", pixelRatio: 3})
      .then(function (blob) {
        // if (window.saveAs) {
        //   window.saveAs(blob, 'my-node.png');
        // } else {
        //   FileSaver.saveAs(blob, 'my-node.png');
        // }
        item = new window.ClipboardItem({ "image/png": blob })           
      })
      await navigator.clipboard.write([item])
      this.$refs.snackbar.show('Image copied to clipboard')
    },
    getRankColor(rank, total/*, val*/){
      // if(val == 0)
      //   return "#bfc5d1";
      //const f = chroma.scale([ '#199dcf', '#28beb9', '#19b271', '#83bc38', '#c9ce44', '#e2a24e', '#d97550', '#ce4e4e']);
      //const f = chroma.scale([ '#ff5448', '#eddb5c', '#83bc38', '#b4eaff', '#58b7db'])
      const f = chroma.scale([ '#58b7db', '#b4eaff', '#83bc38', '#eddb5c', '#ff5448'])
      //console.log(f(0.25).toString())
      return f(rank/total).toString()
      //return `rgba(31, 113, 188, ${(rank/20)*0.77})`
    },
    getLikertColor(rank, total, val){
      if(val == 0)
        return "#e4ecf1"
      const f = chroma.scale([ '#f9a332', '#eddb5c', '#d2d2d2', '#58cedb', '#58b7db'])
      return f(rank/total).toString()
    },
    getRatingColor(rank, total, val){
      if(val == 0)
        return "#e4ecf1"
      const f = chroma.scale([ '#e4ecf1', '#dbdbdb', '#bddbe8', '#b4eaff', '#79daff', '#44c9fc', '#58b7db', '#3f91c2'])
      return f(rank/total).toString()
    },
    getInterviewColor(rank, total, val){
      if(val == 0)
        return "#e4ecf1"
      const f = chroma.scale(['#8AC9CF', '#7EAFC3', '#7CB1D8', '#A3C8E3', '#B2BADE', '#CECEE5', '#E2E2F0', '#E4ECF1'])
      return f(rank/total).toString()
    },
    getTagColor(tags, tag){
      if(!tags || tags.length == 0)
        return "#9EE1DF"
      const f = chroma.scale(['#ccb4f4', '#b6dc8c', '#7ab6cf', '#81dfdf'])
      let c = f(tags.indexOf(tag)/3);
      return {
        bg: c.alpha(0.2).toString(),
        border: c.toString(),
        font: c.darken(2).toString()
      }
    },
    toggle(ev){      
      ev.target.classList.toggle('collapsed');
    },
    async export(fileName){                   
      let csv = "QuestionID,Question,Condition,OptionID,Option,Date,UserID,ResultID,Value,Rank"    
      let relevantSegmentIds = this.questions.map(q => q.segmentId)      
      let relevantResults = this.results.filter(r => relevantSegmentIds.includes(r.segmentId))
      let metadataKeys = Array.from(new Set(relevantResults.flatMap(r => Object.keys(r.metadata)))).filter(m => !m.startsWith('cp_'))
      metadataKeys.forEach(key => {
        csv += `,metadata_${key}`
      });

      relevantResults.forEach(r => {
        let question = this.questions.find(q => q.id == r.questionId)        
        csv += `\n${r.questionId},"${this.stripHtml(question?.Q?.replace(/(")/g, "\"\""))}",${question?.condition ?? ''},${r.optionId},"${this.stripHtml(question?.options?.find(o => o.id == r.optionId)?.text?.replace(/(")/g, "\"\""))}",${r.date},${r.userId},${r.id},${r.value},${r.rank}`
        metadataKeys.forEach(key => {
          csv += `,${r.metadata[key] ?? ''}`
        });
      })
      //console.log(csv)
      let blob = new Blob([csv], {type: "text/plain;charset=utf-8"});
      saveAs(blob, `${fileName ?? 'unknown-session-segment-results-export'}.csv`);
    },
    async export2_1(fileName, externalUserId){          
      let relevantSegmentIds = this.questions.map(q => q.segmentId)      
      let relevantResults = this.results.filter(r => relevantSegmentIds.includes(r.segmentId))
      let metadataKeys = Array.from(new Set(relevantResults.flatMap(r => Object.keys(r.metadata)))).filter(m => !m.startsWith('cp_'))
      //let externalUserId = undefined
      if(metadataKeys?.length){
        // TODO select which one is the external_userId if any (maybe a modal popup will be better), then call the same as below
        // externalUserId = 'pnumber'
        if(!externalUserId && metadataKeys.includes('pnumber'))
          await this.export2_2(fileName, relevantResults, metadataKeys, 'pnumber')
        else
          await this.export2_2(fileName, relevantResults, metadataKeys, externalUserId)
      }
      else
        await this.export2_2(fileName, relevantResults, metadataKeys, null)
    },
    async export2_2(fileName, relevantResults, metadataKeys, externalUserId){   

      let uniqueQuestions = [...new Map(this.questions.map(item =>[item['id'], item])).values()]

      let csv = ''  
      // headers
      if(externalUserId)
        csv += 'ExternalUserID,'
      metadataKeys.forEach(key => {
        csv += `metadata_${key},`
      })
      csv += "Condition,ConditionName,Date,InternalUserID"
      uniqueQuestions.forEach(question => {        
        question.options.forEach(option => {
          csv += `,Q${question.ranking ? 'ranking' : ''}_${question.id}_${option.id}`
        })    
      })

      // question text 'fake' row in csv for human friendlyness
      csv += '\n'
      if(externalUserId)
        csv += ','
      metadataKeys.forEach(() => {
        csv += `,`
      })
      csv += ",,,"
      uniqueQuestions.forEach(question => {        
        question.options.forEach((option,index) => {
          csv += `,"${index == 0 ? this.stripHtml(question?.Q?.replace(/(")/g, "\"\"")) : ''}"`
        })    
      })
      csv += '\n'
      if(externalUserId)
        csv += ','
      metadataKeys.forEach(() => {
        csv += `,`
      })
      csv += ",,,"
      uniqueQuestions.forEach(question => {               
        question.options.forEach(option => {
          csv += `,"${this.stripHtml(option?.text?.replace(/(")/g, "\"\""))}"`
        })    
      })

      // results rows
      let questionsGroupedByConditions = _(this.questions).groupBy('condition').map((value, key) => ({condition: key, questions: value})).value()
      let userResults = _(relevantResults).groupBy(externalUserId ? `metadata.${externalUserId}` : 'userId').map((value, key) => ({userId: key, answers: value})).value()
      
      userResults.forEach(u => {
        // one row for each condition needed per user, in case user has results for multiple conditions...
        let userResultsPerCondition = []
        questionsGroupedByConditions.forEach(g => {
          let tempQuestionIds = g.questions.map(q => q.id)
          let tempSegmentIds = g.questions.map(q => q.segmentId)  // in case of same question with same Id is present in different conditions, then we must further isolate data per row (otherwise data for the question from all conditions might get put in here)
          userResultsPerCondition.push( ({condition: g.condition, answers: u.answers.filter(a => tempQuestionIds.includes(a.questionId) && tempSegmentIds.includes(a.segmentId))}) )
        })

        userResultsPerCondition.forEach(ur => {
          if(ur.answers?.length){
            csv += '\n'
            if(externalUserId)
              csv += `${u.userId},` 

            metadataKeys.forEach(key => {
              // csv += `${ur.answers[0]?.metadata[key]},`  // this only got "first or default" metadata value
              let metadataValues = [...new Set(ur.answers?.filter(u => u.metadata[key]).map(u => u.metadata[key]))]  // in case multiple different metadata for same user              
              csv += `${metadataValues.join(';')},`
            })
                      
            // the date cannot be flattened, we can just only pick the first...
            csv += `${ur.condition == 'undefined' ? '' : ur.condition},${this.conditions?.find(co => co.condition == ur.condition)?.txt1 ?? ''},${ur.answers?.length ? ur.answers[0].date : ''},${externalUserId ? (ur.answers[0] ? ('probably ' + ur.answers[0].userId) : '') : u.userId}`
            
            uniqueQuestions.forEach(question => {              
              question.options.forEach(option => {
                let optionAnswers = ur.answers.filter(a => a.questionId == question.id && a.optionId == option.id)  // here the data is already only for a specific condition, so if question was reused across multiple conditions that will work here correctly
                if(!optionAnswers?.length)
                  csv += `,0`
                else if(optionAnswers.length == 1){
                  csv += `,${optionAnswers[0].value ?? 1}`
                  if(question.ranking)
                    csv += `_rank${optionAnswers[0].rank}`
                }
                // in case of multiple answers for same question/option, use the correlationId to distinguish between them
                else if(optionAnswers.length > 1){
                  let answersGroupedByCorrelationId = _(optionAnswers).groupBy('correlationId').map((value, key) => ({correlationId: key, answers: value})).value()
                  csv += ','
                  answersGroupedByCorrelationId.forEach(element => {
                    csv += `(correlationId:${element.correlationId}`
                    element.answers.forEach(a => {
                      csv += `/${a.value ?? 1}`
                      if(question.ranking)
                        csv += `_rank${a.rank}`
                    })                  
                    csv += ')'
                  })
                }
              })    
            })
          }
        })        

      })

      let blob = new Blob([csv], {type: "text/plain;charset=utf-8"});
      saveAs(blob, `${fileName ?? 'unknown-session-segment-results-export'}.csv`);
    },
    stripHtml(html){
      return html?.replace(/(<([^>]+)>)/gi, "")
    }, 
    answerCount(question){     
      return (question.type && question.type.startsWith('interview'))
        ? new Set(this.res(question.id).map(r => r.userId))?.size ?? 0 
        : new Set(this.res(question.id).map(r => r.correlationId ?? r.date))?.size ?? 0 // date just for backward compatibility
    },
    res(questionId, optionId){
      return this.results.filter(r => 
        r.questionId == questionId 
        && (!optionId || (r.optionId == optionId) || (!r.optionId && (r.value == optionId)) /* for ratings mapping to 1,2,3,4,5 */)        
        &&  r.segmentId == this.segmentId)     
    },
    resPercentage(questionId, optionId){      
      let p = this.res(questionId, optionId).length/this.res(questionId).length*100
      return p ? (Math.round(p * 100) / 100)  : 0     
    },
    resAverage(questionId, optionId){          
      let p = _.meanBy(this.res(questionId, optionId), r => r.value ?? r.optionId)
      return p ? (Math.round(p * 100) / 100)  : 0  
    },
    resSum(questionId, optionId){      
      let p = _.sumBy(this.res(questionId, optionId), 'value')
      return p ? (Math.round(p * 100) / 100)  : 0  
    },
    ratingAverage(question){
      let sum = 0
      let count = 0
      question.options.forEach(option => {
        let localCount = this.res(question.id, option.id).length
        let localSum = localCount * option.id // rating is done with having options from 1 to 5
        sum += isNaN(localSum) ? 0 : localSum
        count += isNaN(localCount) ? 0 : localCount
      })
      let ret = sum / count
      return isNaN(ret) ? 0 : ret
    },    
    resCorrect(question){      
      let res = this.res(question.id)
      let correctAnswers = question.Answers ?? []
      if(question.Answer && !correctAnswers.includes(question.Answer))
        correctAnswers.push(question.Answer)
      //let userResults = toArray(groupBy(res, 'userId'))
      let userResults = _(res).groupBy('userId').map((value, key) => ({userId: key, answers: value.map(v => v.optionId)})).value()
      let count = _.countBy(userResults, (u) => _.xor(u.answers, correctAnswers).length == 0)      
      // if(!this.temp)
      //   this.temp = res
      //console.log(count)
      return count.true ?? 0
    },
    resRank(question){
      let rankedResults = question.options.map(o => this.optionRank(question, o))
      return _.orderBy(rankedResults, ['score'], ['desc'])
    },
    standardDeviation(array){
      if(!array || array.length == 0)
        return 0
      var avg = _.sum(array) / array.length;
      return Math.sqrt(_.sum(_.map(array, (i) => Math.pow((i - avg), 2))) / array.length);
    },
    resStandardDeviation(questionId, optionId){      
      const p = this.standardDeviation(this.res(questionId, optionId).map(r => r.value ?? r.optionId))
      return p ? (Math.round(p * 100) / 100)  : 0  
    },
    resLikert(questionId, optionId, colorAsRating){
      let tmp = this.res(questionId, optionId)
      let grouped = _.groupBy(tmp, 'value')      
      let mapped = [1,2,3,4,5].map(score => {           
        return {
          count: grouped[`${score}`]?.length ?? 0,
          score: score,
          percentage: (grouped[`${score}`]?.length ?? 0) / tmp.length
        }
      })
      let ordered = _.orderBy(mapped, 'count')      
      mapped.forEach(element => {        
        element.color = colorAsRating 
        ? this.getRatingColor(ordered.findIndex(oe => oe.score == element.score), 4, element.count)
        : this.getLikertColor(ordered.findIndex(oe => oe.score == element.score), 4, element.count)
      })
      return mapped
    },
    optionRank(question, option){
      let score = 0
      let distribution = []
      let res = this.res(question.id, option.id)    
      //debugger
      // weighted average score, #1 choice has highest weight, #n has lowest weight (in reverse)
      for (let r = 1; r <= question.options.length; r++) {
        const w = question.options.length + 1 - r
        const c = res.filter(u => u.rank == r).length
        const d = Math.round(c/res.length*100*10)/10
        distribution.push(isNaN(d) ? 0 : d)
        score += c*w;
      }

      const s = Math.round(score / res.length * 100) / 100
      return {option: option, score:  isNaN(s) ? '-' : s, distribution: distribution.filter(f => f > 0).length ? distribution : null}
    },
    toggleInterviewee(question, item){
      let x = question.interviewees.find(i => i.id == item.id)
      //this.$set(x, 'expanded', !x.expanded)
      x.expanded = !x.expanded
    },
    toggleInterviewMode(question, mode){
      let x = this.questions.find(i => i.id == question.id)
      this.$set(x, 'mode', mode)
      this.tempVueBugHack = mode
      //question.mode = mode
      //console.log(question.mode)
    },    
    toggleFuQ(q/*, iv*/){    
      //console.log(q.selected)  
      //this.$set(q, 'selected', !q.selected)  
      q.selected = !q.selected    
      //console.log(q.selected)
      //console.log(iv)
    },
    resInterview(item){      
      //console.log("res recalc")
      let res = this.res(item.id)
      let interviewees = item.interviewees.map(iv => {
        let ivres = res.filter(r => r.optionId == iv.id)   
        let qres = this.res(iv.id)   
        let qresByUser = _(qres).groupBy('userId').map((value, key) => ({userId: key, answers: value})).value()
        const p = ivres.length / res.length
        const avg = _.meanBy(qresByUser, r => r.answers.length)  
        iv.questions.forEach(q => {
          q.count = qres.filter(r => r.optionId == q.optionId).length    
          if(q.tag){
            q.color = this.getTagColor(item.tags, q.tag)
          }
          //return q
          if(item.followupQuestions){
            this.$set(q, "selected", q.selected ?? false)
            //q.selected = q.selected ?? false
            q.level = 0
            q.followupQuestions?.forEach(q1 => {
              if(q1.tag){
                q1.color = this.getTagColor(item.tags, q1.tag)
              }
              q1.count = qres.filter(r => r.optionId == q1.optionId).length    
              q1.parentQuestion = q             
              this.$set(q1, "selected", q1.selected ?? false)       
              //q1.selected = q1.selected ?? false
              q1.level = 1
              q1.followupQuestions?.forEach(q2 => {
                if(q2.tag){
                  q2.color = this.getTagColor(item.tags, q2.tag)
                }
                q2.count = qres.filter(r => r.optionId == q2.optionId).length                        
                q2.parentQuestion = q1
                this.$set(q2, "selected", q2.selected ?? false)
                //q2.selected = q2.selected ?? false
                q2.level = 2
              })  
            })  
          }
        })       
        //let qranked = _.orderBy(iv.questions, ['count'], ['desc'])     
        let distinctValues = _.orderBy([...new Set(iv.questions.map(q => q.count))])            
        if(item.chooseQuestions){           
          iv.questions.forEach(q => {
            //q.rank = qranked.findIndex(qr => qr.optionId == q.optionId) + 1,
            q.colorRank = distinctValues.findIndex(c => c == q.count) // needed for rank based coloring
          })  
          
        }
        return {
          id: iv.id,
          icon: iv.icon,
          text: iv.textDashboard ? iv.textDashboard : iv.text,
          questionCount: qres.length,
          count: ivres.length,
          percentage:   p ? (Math.round(p * 100) / 100)  : 0,
          averageDepth: avg ? (Math.round(avg * 100) / 100) : 0,
          questions: /*item.chooseQuestions ? qranked :*/ iv.questions,
          colorRankBase: Math.max(distinctValues.length - 1, 0)
        }
      })

      if(item.followupQuestions){
        let total = _.sumBy(interviewees, 'questionCount')
        interviewees.forEach(iv => {
          const p = iv.questionCount / total
          iv.percentage = p ? (Math.round(p * 100) / 100) : 0
        })
      }      
      //console.log('DONE WITH IV')
      return _.orderBy(interviewees, item.chooseQuestions ? ['questionCount'] : ['averageDepth'], ['desc']) 
    },
    resInterviewByTag(item){      
      //let res = this.res(item.id)
      let intervieweeIds = item.interviewees.map(i => i.id)
      let qres = this.results.filter(r => 
        intervieweeIds.includes(r.questionId)                
        && r.segmentId == this.segmentId
      )  
      if(!item.tags){        
        return []
      }
      let tags = item.tags.map(tag => {
        let tmp = {
          name: tag,
          tagIdPairs: [],
          count: 0,
          percentage: 0,
          color: undefined
        }

        for (let i = 0; i < item.interviewees.length; i++) {
          const interviewee = item.interviewees[i]
          for (let j = 0; j < interviewee.questions.length; j++) {
            const question = interviewee.questions[j];
            if(question.tag == tag)
              tmp.tagIdPairs.push({qId: interviewee.id, oId: question.optionId})
          }
        }

        tmp.color = this.getTagColor(item.tags, tag)
        tmp.count = qres.filter(r => tmp.tagIdPairs.find(p => r.questionId == p.qId && r.optionId == p.oId)).length
        let p = tmp.count/qres.length
        tmp.percentage = p ? (Math.round(p * 100) / 100) : 0

        return tmp
      })

      //console.log('DONE WITH TAGS')
      return tags 
    },
    resInterviewTop5(item, order){
      //let res = this.res(item.id)
      let r = item.interviewees.flatMap(iv => iv.questions.map(q => 
        {
          //let ivres = res.filter(r => r.optionId == iv.id) 
          let qres = this.res(iv.id)
          let interviewee = {
            id: iv.id,
            icon: iv.icon
          }
          let question = {
            optionId: q.optionId,
            title: q.title ? q.title : q.question,
            count: qres.filter(r => r.optionId == q.optionId).length,
            tag: q.tag,
            color: q.tag ? this.getTagColor(item.tags, q.tag) : undefined
          }          
          return {interviewee, question}
        }
      ))
      //console.log(r)

      let total = _.sumBy(r, 'question.count') // sum of "first level" questions (followup questions excluded if any)      
      r.forEach(i => {
        const p = i.question.count / total
        i.percentage = p ? (Math.round(p * 100) / 100) : 0
      })
      
      let r2 = _.orderBy(r, ['question.count'], [order]) 
      for (let i = 0; i < r2.length; i++) {
        const element = r2[i];
        element.rank = (order == 'desc') ? i+1 : r2.length - i
      }

      return _.take(r2,5)
    },
    resFunnel(questions){   
      let scaleLeft = 5   
      for (let i = 0; i < questions.length; i++) {        
        let q = questions[i];
        if(i == 0 && q.count > 0)
          scaleLeft = 100

        let qNext = (i+1) < questions.length ? questions[i+1] : q // last element is same both on left and right edges           
        let scaleRight = Math.min((qNext.count / q.count * 100), scaleLeft)     
        if(isNaN(scaleRight) || scaleRight == Infinity || scaleRight == 0)
          scaleRight = 5 

        q.clipPath = `polygon( 0% ${(100 - scaleLeft)/2}%, 100% ${(100 - scaleRight)/2}%, 100% ${100 - ((100 - scaleRight)/2)}%, 0% ${100 - ((100 - scaleLeft)/2)}% )`        
        
        scaleLeft = scaleRight
      }
      return questions
    },
    setAnimationMaxHeight(){
      document.querySelectorAll('.question .answers').forEach(el => {
        el.style.maxHeight = el.clientHeight + 'px';
      })
    },
    initTagCharts(){
      document.querySelectorAll('.tagchart').forEach(canvas => {        
        const chartId = canvas.getAttribute('id').split('_')[1]    
        let chart = Chart.getChart(canvas.getAttribute('id'))        
        
        let question = this.questions.find(q => q.id == canvas.getAttribute('questionId'))   
        if(!question || !question.tags || question.tags.length == 0)
          return;

        let interviewee = question.interviewees.find(i => i.id == chartId)     
        let qres = this.res(interviewee.id)
        let tagDistribution = question.tags.map(tag => {
          let tmp = {
            name: tag,
            tagIdPairs: [],
            count: 0,
            percentage: 0
          }
          
          for (let j = 0; j < interviewee.questions.length; j++) {
            const q = interviewee.questions[j];
            if(q.tag == tag)
              tmp.tagIdPairs.push({qId: interviewee.id, oId: q.optionId})
          }
          
          tmp.count = qres.filter(r => tmp.tagIdPairs.find(p => r.questionId == p.qId && r.optionId == p.oId)).length
          let p = tmp.count/qres.length
          tmp.percentage = p ? (Math.round(p * 100) / 100) : 0
          tmp.color = this.getTagColor(question.tags, tag).border

          return tmp
        })

        if(!chart){
          chart = new Chart(
            canvas,
            {
              type: 'doughnut',
              data: {
                labels: tagDistribution.map(t => `${t.name}  ${Math.round( ((t.count /_.sumBy(tagDistribution, 'count')) || 0) * 100)}%`),
                datasets: [
                  {
                    //label: 'Acquisitions by year',
                    data: tagDistribution.map(t => t.count),
                    backgroundColor: tagDistribution.map(t => t.color),
                    pointStyle: 'circle'
                  }
                ]
              },
              //plugins: tagDistribution.find(t => t.count > 0) ? [ChartDataLabels] : [],
              plugins: [ChartDataLabels],
              options:{
                plugins: {
                  legend: {
                    labels: {
                      usePointStyle: true,
                      pointStyle: 'circle',
                    },
                    position: 'right'
                  },
                  tooltip:{
                    callbacks: {
                      // title: function(tooltipItem) {
                      //   var title = question.tags[tooltipItem[0].dataIndex];
                      //   return title;
                      // }
                      label: function(context) {                   
                        return `  ${question.tags[context.dataIndex]} ${context.parsed}`;
                      }
                    }    
                  },
                  datalabels: {
                    // formatter: (value/*, ctx*/) => {
                    //     // let sum = 0;
                    //     // let dataArr = ctx.chart.data.datasets[0].data;
                    //     // dataArr.map(data => {
                    //     //     sum += data;
                    //     // });
                    //     // let percentage = (value*100 / sum).toFixed(2)+"%";
                    //     return value;
                    // },
                    color: function(ctx) {
                        return ctx.dataset.data.find(x => x > 0) ? '#000' : '#fff'
                    }
                  }           
                }              
              }
            }
          );
        }
        else{
          chart.data.datasets[0].data = tagDistribution.map(t => t.count)
          chart.data.datasets[0].backgroundColor = tagDistribution.map(t => t.color)
          chart.data.labels = tagDistribution.map(t => `${t.name}  ${Math.round( ((t.count /_.sumBy(tagDistribution, 'count')) || 0) * 100)}%`)
          chart.update()  
        }
          
      });
    }
  },
  watch: {
    questions: function(){
      setTimeout(() => this.initTagCharts(), 500) // TODO will need to rework each question type into a separate component, otherwise it is getting messy
    },
    results: function(){
      this.initTagCharts()
    }
  },
  created() {
  },
  mounted(){       
    this.initTagCharts()
  }  
}
</script>



<style lang="scss">

.results2{
  // margin: 15px auto 15px auto;
  //border: $bordermixin;
  //border-radius: $borderradius;
  position: relative;
  clear: both;

  .conditions{
    padding: 20px 20px 0 20px;
    overflow: hidden;
    border-bottom: $bordermixin;
    background-color: white;

    .condition{
      margin-bottom: 0;
      padding-bottom: 14px;
    }    
  }

  .showdetails{
    color: $july;
    position: absolute;
    right: 10px;
    top: 18px;

    span{
      vertical-align: middle;
      font-size: 14px;
      padding-left: 4px;
    }
    input{
      vertical-align: middle;
      cursor: pointer;
    }
  }

  .question{
    margin: 0 20px 0 0;
    border-bottom: $bordermixin;
        
    .title{      
      padding: 17px 100px 17px 40px;
      //background-color: #F8F9FB;
      color: $december;
      font-size: 16px;
      font-weight: bold;
      padding-right: 100px;
      cursor: pointer;
      position: relative;

      &:after{
        content: '\276E';
        position: absolute;
        font-size: 18px;
        left: 10px;
        top: 30%;
        color: #00684b;
        opacity: 0.5;
        transform: rotate(90deg);
        transition: transform 0.5s ease;      
      }

      &:hover:after{
        opacity: 1;
      }

      &.collapsed{
        overflow: hidden;
        & + .answers{
          max-height: 0px !important;
          padding-bottom: 0px;
          padding-top: 0px;
          overflow: hidden;               
          transform-origin: top;  
          transform: scaleY(0); 
          margin-top: 0;
          margin-bottom: 0;
        }

        &:after{
          transform: rotate(270deg);
        }
      }

      .totalcount{
        color: #777;
        font-size: 14px;
        position: absolute;
        right: 0;
        top: 30%;
        font-weight: normal;

        span{
          color: #58b7db;
          display: inline-block;
          border-radius: 50px;
          width: 25px;
          height: 25px;
          border: solid 2px #58b7db;
          background-color: #fff;
          text-align: center;
          padding-top: 2px;
          margin-right: 3px;

          &.zero{
            border: solid 1px #777;
            width: 24px;
            height: 24px;
            color: #777777;
          }

          &.new{
            background-color: #58b7db;
            color: white;
          }
        }
      }
    }

    &.ranking{
      .score{
        font-size: 15px;
        //font-weight: bold;
        text-align: center;
      }
      th{
        color: #686E71;
        font-weight: bold;
        font-size: 15px;
        margin-bottom: 10px;
        text-align: left;
      }
      .bar{
        display: flex;
        gap:2px;        
      }      
    }

    .answers{
      margin: 0px 40px 0 40px;
      transition: all 0.5s ease;
      transform: scaleY(1); 

      .palette{ 
        text-align: right;
        color: #777;
        span{
          display: inline-block;
          margin: 0 3px;
          width: 16px;
          height: 16px;
          border-radius: 2px;
          vertical-align: top;
        }
      }

      table{
        width: 100%;

        th{
          font-weight: normal;
          color: #777;
          font-size: 15px;
        }

        td{
          padding: 15px 0;  
        }

        .option.hasImage td{
          padding:4px 0;     
        }       
        
        .option:last-child{
          td{
            padding-bottom: 15px;
          }
        }

        .option:not(:last-child) td{
          border-bottom: 1px solid #d8e7f180;
        } 

        .option.noborder td{
          border-bottom: 0px !important;
        }
      }

      .correctcount{
        font-size: 13px;
        float: right;
      }

      .option{

        .label{
          font-weight: bold;
          font-size: 15px;
          padding-right: 25px;
          width: 50%;

          &.correct{
            color: green;
            position: relative;
            &:before{
              content:'';
              width:20px;
              height:20px;
              background: url(../assets/check.svg);
              position: absolute;
              left: -40px;
              top: 50%;
              transform: translateY(-50%);
            }
          }
          
        }

        &.hasImage{
          table td {
            padding: 4px 0;
          }

          .label > div{
            display: flex;
            align-items: center;
          }

          .label img{
            width: 48px;
            height: 48px;
            border-radius: 4px;
            margin-right: 16px;
          }
        }

        .chart{
          //background-color: red;
          min-width: 400px;

          .bar{
            width: 100%;
            border-radius: $borderradius;
            background-color: $january;
            height: 24px;
            position: relative;

            

            .progress{
              background-color:  #58b7db;
              width: 33%;
              height: 24px;
              border-radius: $borderradius;
              position: absolute;

              &.distribution{
                .value{
                  //color: white;
                }
                //min-width: 70px;
                flex-basis: 30px;
                width: auto;
                overflow: hidden;
                float: left;
                position: relative;
                //border-left: 2px dotted white;
                
              }
            }

            .value{
              position: absolute;
              left: 0;
              right: 0;
              text-align: center;
              height: 24px;
              line-height: 24px;
              //color: #686E71;
              font-size: 12px;
              font-weight: bold;
            }
          }

          .average, .sum{
            color: $july;
            margin-top: 5px;
            font-size: 15px;

            span{
              color: black;
              font-weight: bold;
            }
          }
        }
      }
    }

    &.likert{
      th{
        color: #777;
        white-space: nowrap;
        padding-bottom:8px;
      }
      
      .answers {
        .palette{
          padding-top: 0;
        }
        table {
          td{
            padding: 10px 0;          
          }
          .option{ 
            td{
              border: none;
              padding: 0 0 20px 0;
            }
            .label{

            }
          }
        }
      }
      .textscale{
        display: flex;
        justify-content: space-between;
        
        div{
          flex: 1;
          text-align: center;
        }
      }
      .chart > div{
        display: flex;
        gap: 2px;
        justify-content: space-between;

        div{
          background-color: #e4ecf1;
          color: white;
          text-align: center;
          flex-grow: 1;
          height: 24px;
          line-height: 24px;
          border-radius: 2px;
          font-size: 12px;
          font-weight: bold;
        }
      }
    }

    &.rating{
      .answers{
        padding-bottom:15px;
        table{
          .option{
            td{
              padding: 10px 0;
              border-bottom:0px;
            }
          }
          .option.hasImage{
            td{
              padding: 4px 0;
              //border-bottom:0px;              
            }
            &:not(:last-child) td{
              border-bottom: 1px solid #d8e7f180;
            }
          }          
        }
        .stars{
          height:16px;
          width: 95px;
          background: url(../assets/star.svg) space;
          position: relative;

          div{
            position:absolute;
            height:16px;
            width: 95px;
            background: url(../assets/yellowstar.svg) space;
          }
        }
      }

      .chart > div{
        display: flex;
        gap: 2px;
        justify-content: space-between;

        div{
          //background-color: #e4ecf1;
          //color: white;
          font-size:12px;
          font-weight: bold;          
          text-align: center;
          flex-grow: 1;
          height: 24px;
          line-height: 24px;
          border-radius: 2px;
        }
      }
    }

    &.interview{
      th, td {
        padding-right: 8px;
      }

      tr.ivq {
        &.collapsed{
          display: none;
        }
        td{
          padding: 6px 0;
        }
        &.last{
          > td{
            border-bottom: 1px solid #d8e7f180;
            padding-bottom: 20px;
          }
        }
        .dot{
          min-width: 30px;
          height: 18px;
          //vertical-align: middle;
          display: block;
          border-radius: 2px;
          margin-right: 8px;
          text-align: center;
          font-size: 12px;
          font-weight: bold;
          line-height: 18px;
          flex-grow: 0;
          flex-basis: 30px;
        }

        .left{
          display: flex;
          align-items: center;
          // > div {
          //   flex-grow: 1;
          // }
        }
      }    

      td.toggle{
        position: relative;
        width: 10px;
        min-width: 10px;       

        &:after{
          content: "❮";
          position: absolute;
          font-size: 18px;
          left: 0;
          top: 30%;
          color: #00684b;
          opacity: 0.5;
          transform: rotate(90deg);
          transition: transform 0.5s ease;
        }
      }

      .type2 td.toggle, .type3 td.toggle{
        min-width:30px;
      }

      td.rank{
        width: 45px;
      }

      .buttons{
        height: 28px;
        border-radius: 8px;
        border: solid 2px #eef2f5;
        background-color: #fff;
        color: #4cad82;
        position: relative;
        font-size: 15px;
        display: inline-block;
        
        div{
          display: inline-block;
          min-width: 96px;
          padding: 0 10px;
          text-align: center;
          line-height: 28px;
          height: 28px;
          top: -2px;
          position: relative;
          cursor: pointer;
          border-radius: 8px;

          &.selected{
            color: #fff;
            background-color: #4cad82;
            height: 28px;
            line-height: 28px;
          }

          &:not(.selected):hover{
            color: black;
          }
        }
      }

      .tag{
        //margin-left: 4px; 
        padding: 2px 6px;
        border-radius: 5px;
        font-size: 12px;   
        border: 1px solid black; 
      }
      
      .answers table .option{
        &:hover{
          cursor: pointer;
          .toggle::after{
            opacity: 1;
          }
        }

        &.collapsed {          
          td.toggle:after{
            transform: rotate(270deg);
          }
          .funnel{
            display: none;
          }
          .bar{
            display: block;
          }
        }

        &:not(.collapsed){
          > td{
            border-bottom: none;
            padding-bottom: 8px;
          }
          .bar{
            display: none;
            &.showWhenExpanded{
              display: block;
            }
          }
        }
        &.last{
          > td{
            border-bottom: none;
          }
        }
      }

      .funnel{
        display: flex;
        gap: 2px;
        justify-content: space-between;
        position: relative;

        div.col{
          background-color: #e4ecf1;
          color: #000;
          text-align: left;
          padding-left: 8px;
          flex-grow: 1;
          height: 78px;
        }
        div.values{
          position: absolute;
          left: 0;
          right: 0;
          display: flex;
          align-items: center;
          height: 78px;

          div{
            flex-grow: 1;            
            text-align: center;
          }
        }
      }

      .distribution{
        display: flex;
        gap: 2px;
        justify-content: space-between;

        div{
          background-color: #e4ecf1;
          color: white;
          text-align: center;
          flex-grow: 1;
          height: 24px;
          line-height: 24px;
          border-radius: 2px;
          font-size: 12px;
          font-weight: bold;
        }
      }

      .tagchart{
        max-height: 120px;
      }

      .followup{
        display: flex;
        overflow-x: auto;

        .q{
          flex: 0 0 382px;      
          //align-items: center;  
          justify-content: center;
          display: flex;
          flex-direction: column;  

          > div{
            display: flex;
            //gap: 12px;
            align-items: center;
          }
        }

        .lineclamp{
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;  
          overflow: hidden;
        }

        .fuQ{
          height:64px;
          flex-basis: 316px;
          padding: 8px;
          border-radius: 4px;
          border: solid 2px #eef2f5;
          background-color: #eef2f5;
          line-height: 1.4;
          margin: 11px 0;
          flex-grow: 1;
          cursor: pointer;          

          &:hover{
            box-shadow: 0 0 10px #cdebdd;
          }

          &.zero{
            border: solid 2px #e4ecf1;
            background-color: #fff;
          }

          .tag{
            float: right;
          }
        }

        .count{
          margin-right:12px;
          flex-grow: 0;
          flex-shrink: 0;
          color: #58b7db;
          display: inline-block;
          border-radius: 50px;
          width: 25px;
          height: 25px;
          border: solid 2px #58b7db;
          background-color: #fff;
          text-align: center;
          padding-top: 1px;

          &.zero{
            border: solid 1px #777;
            width: 24px;
            height: 24px;
            color: #777777;
            padding-top: 2px;
          }          
        }

        .arrow{
          width: 20px;
          position: relative;
          overflow: hidden;
          
          &.right{
            border: solid 1px #58b7db;
            height: 0;
          }

          &.left{
            height: 100%;
            margin-right: 12px;                        

            &:before{
              content: '';  
              display: block;
              border: solid 1px #58b7db;
              height: 0;    
              position: absolute;
              //top: calc(50% - 1px);                     
              width:20px; 
            }
            &:after {
              content: ''; 
              display: block;
              width: 0; 
              height: 0; 
              border-top: 5px solid transparent;
              border-bottom: 5px solid transparent;              
              border-left: 8px solid #58b7db;
              position: absolute;
              right: 0;
              //top: calc(50% - 5px);
            }
          }
        }

        .q > div:first-child{
          .arrow.left:not(.selected):before{
            width:100px;
            height: 100px;
            border-radius: 10px;
            border: solid 2px #58b7db;
            top: calc(50% - 1px);      
          }
        }
        .q > div:last-child{
          .arrow.left:not(.selected):before{
            width:100px;
            height: 100px;
            border-radius: 10px;
            border: solid 2px #58b7db;
            top: unset;
            bottom: calc(50% - 1px);
          }
        }
        .q > div:not(:first-child):not(:last-child){
          .arrow.left:not(.selected){
            border-left: solid 2px #58b7db;
          }
        }
      }
    }
  }
}

</style>
